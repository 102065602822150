<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.id')"
                :placeholder="$t('labels.id')"
                name="id_identity"
                sort-name="id_identity"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.id_customer')"
                :placeholder="$t('labels.id_customer')"
                name="id"
                sort-name="id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.date_create')"
                :placeholder="$t('labels.date_create')"
                name="created_at"
                sort-name="created_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.customer')"
                :placeholder="$t('labels.customer')"
                name="company_name"
                sort-name="company_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.account')"
                :placeholder="$t('labels.account')"
                name="email"
                sort-name="email"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectCountry
                class="c-input-xs"
                :label="$t('labels.country')"
                :placeholder="$t('labels.country')"
                name="id_country"
                sort-name="country_name"
                :sorting="filters.sort_by"
                @onChange="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                show-not-warehouse
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectMaterialWarehouse
                :label="$t('labels.material_warehouse')"
                :placeholder="$t('labels.material_warehouse')"
                name="id_material_warehouse"
                sort-name="material_warehouse_code"
                show-not-warehouse
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectPos
                :label="$t('labels.pos')"
                :placeholder="$t('labels.pos')"
                name="id_pos"
                sort-name="pos_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="statusOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="status"
                sort-name="status"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="customer in items" :key="customer.id" class="text-center">
            <td>{{ customer.id_identity }}</td>
            <td>{{ customer.id }}</td>
            <td>{{ formatDateTime(customer.created_at) }}</td>
            <td>
              <span
                v-if="checkPermission(['super_admin'])"
                class="blue--text text-decoration-underline cursor-pointer"
                @click="showDetailDialog(customer)"
                >{{ customer.company_name }}</span
              >
              <span v-else>{{ customer.company_name }}</span>
            </td>
            <td>{{ customer.email }}</td>
            <td>{{ customer.country_name }}</td>
            <td>{{ customer.customer_warehouse_codes }}</td>
            <td>{{ customer.customer_material_warehouse_codes }}</td>
            <td>
              <span
                v-if="checkPermission(['super_admin'])"
                class="blue--text text-decoration-underline cursor-pointer"
                @click="showPosDialog(customer)"
              >
                {{
                  (customer.customer_pos_codes &&
                    customer.customer_pos_codes.trim()) ||
                  $t("labels.config")
                }}
              </span>
            </td>
            <td>
              <v-switch
                class="switch-center"
                v-model="customer.status"
                @change="switchCustomerStatus(customer)"
                :key="`customer_${customer.id}_${customer.status}`"
              ></v-switch>
            </td>
            <td>
              <v-btn
                x-small
                color="success"
                outlined
                block
                class="mb-1"
                @click="showConfigDialog(customer)"
              >
                {{ $t("labels.config") }}
              </v-btn>

              <v-btn
                x-small
                color="warning"
                outlined
                block
                class="mb-1"
                :disabled="!customer.status"
                @click="loginCustomer(customer)"
              >
                {{ $t("labels.login") }}
              </v-btn>

              <v-btn
                x-small
                color="purple"
                outlined
                block
                @click="showSubAccountDialog(customer)"
              >
                {{ $t("labels.employee") }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="3">
        <div class="d-flex table-footer-count mt-3">
          <div class="mr-2">
            {{ $t("labels.customer") }}: <b>{{ formatNumber(totalItem) }}</b>
          </div>
          <div class="mr-2">
            Active: <b>{{ formatNumber(totalActive) }}</b>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="12" sm="3"></v-col>
    </v-row>

    <v-dialog v-model="detailDialog" persistent max-width="800px">
      <DialogDetail
        :key="`c_${customer.id}`"
        :customer="customer"
        v-if="detailDialog && customer && customer.id"
        @closeDialog="hideDetailDialog"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="posDialog" persistent max-width="100vw">
      <DialogPos
        :key="`cp_${customer.id}`"
        :customer="customer"
        v-if="posDialog && customer && customer.id"
        @closeDialog="hidePosDialog"
      />
    </v-dialog>

    <v-dialog v-model="configDialog" persistent max-width="640px">
      <DialogConfig
        :customer="customer"
        :key="`cd_${customer.id}`"
        v-if="configDialog && customer && customer.id"
        @closeDialog="hideConfigDialog"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="subAccountDialog" persistent max-width="100vw">
      <SubAccount
        :key="`cs_${customer.id}`"
        :customer="customer"
        v-if="subAccountDialog && customer && customer.id"
        @closeDialog="hideSubAccountDialog"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectMaterialWarehouse: () =>
      import("@/components/table/SelectMaterialWarehouse"),
    SelectPos: () => import("@/components/table/SelectPos"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    DialogDetail: () => import("@/components/customer/DialogDetail"),
    DialogPos: () => import("@/components/customer/DialogPos"),
    DialogConfig: () => import("@/components/customer/DialogConfig"),
    SubAccount: () => import("@/components/customer/SubAccount"),
    SelectCountry: () => import("@/components/common/SelectCountry"),
  },
  name: "Index",
  data: () => ({
    page: 1,
    totalItem: 0,
    totalActive: 0,
    totalPage: 1,
    items: [],
    filters: {},
    statusOptions: [
      {
        text: "Active",
        value: 10,
      },
      {
        text: "Inactive",
        value: 0,
      },
    ],
    customer: {},
    detailDialog: false,
    posDialog: false,
    configDialog: false,
    subAccountDialog: false,
    isLoading: false,
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    getList: debounce(function () {
      httpClient
        .post("/customer/v1/list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.totalItem = data.total;
          this.totalActive = data.totalActive;
          this.items = [...data.rows].map((r) => {
            r.status = r.identity_status === 10;
            return r;
          });
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showSubAccountDialog(customer) {
      this.customer = { ...customer };
      this.subAccountDialog = true;
    },
    hideSubAccountDialog() {
      this.customer = {};
      this.subAccountDialog = false;
    },
    async loginCustomer(customer) {
      if (!customer.status) {
        return false;
      }

      if (
        !confirm(`Bạn có muốn đăng nhập tài khoản ${customer.company_name}`)
      ) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/customer/v1/login-with", {
          id_identity: customer.id_identity,
        });

        this.isLoading = false;

        if (!data || !data.key) {
          this.$vToastify.error("ERROR (X_X)");
          return false;
        }

        window.open(`${process.env.VUE_APP_WEB_CUSTOMER_URL}/al?k=${data.key}`);
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async switchCustomerStatus(customer) {
      if (
        !confirm(
          `Bạn có chắc chắn muốn cập nhật trạng thái ${customer.company_name}`
        )
      ) {
        this.getList();
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/customer/v1/update-status", {
          identity_id: customer.id_identity,
          status: customer.status ? 10 : 0,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
      } catch (e) {
        this.isLoading = false;
      }
    },
    showDetailDialog(customer) {
      this.customer = { ...customer };
      this.detailDialog = true;
    },
    hideDetailDialog() {
      this.customer = {};
      this.detailDialog = false;
    },
    showPosDialog(customer) {
      this.customer = { ...customer };
      this.posDialog = true;
    },
    hidePosDialog() {
      this.customer = {};
      this.posDialog = false;
    },
    showConfigDialog(customer) {
      this.customer = { ...customer };
      this.configDialog = true;
    },
    hideConfigDialog() {
      this.customer = {};
      this.configDialog = false;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped></style>
